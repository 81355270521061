import { QuillModule } from "ngx-quill";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { ContextMenuModule } from "ngx-contextmenu";
import { BrowserModule } from "@angular/platform-browser";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { reducers } from "./store/reducers";
import { LastTagPipe } from "./last-tag.pipe";
import { AppComponent } from "./app.component";
import { BROWSER_FAVICONS_CONFIG } from "./favicons";
import { BrowserFavicons } from "./favicons";
import { Favicons } from "./favicons";
import * as fromFile from "./store/reducers/file";
import * as fromTask from "./store/reducers/task";
import * as fromSbarAlerts from "./store/reducers/sbar-alerts";
import * as notificationReducer from "./store/reducers/notifications";
import * as fromProtocol from "./protocol/reducers";
import { OrdersModule } from "./orders/orders.module";
import { SharedModule } from "./shared/shared.module";
import { IoEffects } from "./store/effects/io.effects";
import { environment } from "src/environments/environment";
import { AuthEffects } from "./store/effects/auth.effects";
// import { NotebookModule } from "./notebook/notebook.module";
import * as fromOrderable from "./store/reducers/orderable";
import { AddTimeToDatePipe } from "./add-time-to-date.pipe";
import { NotesEffects } from "./store/effects/notes.effects";
import { OrderEffects } from "./store/effects/order.effects";
import { FileEffects } from "./protocol/effects/file.effects";
import * as fromAuth from "src/app/store/reducers/auth/index";
import { SocketEffects } from "./store/effects/socket.effects";
import { VitalsEffects } from "./vitals/store/effects/vitals.effects";
import { MessageEffects } from "./store/effects/message.effects";
import { ScheduleGeneratorPipe } from "./schedule-generator.pipe";
import { RegisterComponent } from "./register/register.component";
import { CpHeaderComponent } from "./cp-header/cp-header.component";
import { CpFooterComponent } from "./cp-footer/cp-footer.component";
import * as fromPatient from "src/app/store/reducers/patients/index";
import { DocumentsEffects } from "./store/effects/documents.effects";
import { OrderableEffects } from "./store/effects/orderable.effects";
import { ProtocolEffects } from "./protocol/effects/protocol.effects";
import * as fromHospital from "src/app/store/reducers/hospitals/index";
import * as fromUnit from "src/app/store/reducers/units";
import { SummaryComponent } from "./patient/summary/summary.component";
// import { HospAdminComponent } from "./hosp-admin/hosp-admin.component";
import * as fromIo from "src/app/store/reducers/patient-chart/io/index";
import { HospitalEffects } from "./store/effects/hosptial-list.effects";
// import { NgbTimeStringAdapter } from "./ngb-time-string-adapter.service";
import { DaysPassedPipe } from "./shared/daysPassedPipe/daysPassed.pipe";
import { PatientListEffects } from "./store/effects/patient-list.effects";
import { AppRoutingModule, routingComponents } from "./app-routing.module";
import { PatientHeaderEffects } from "./store/effects/patient-header.effects";
import * as fromChat from "src/app/patient/chat/store/reducers/index";
// import * as fromNotes from "src/app/store/reducers/patient-chart/notes/index";
import { TokenInterceptorService } from "./services/token-interceptor.service";
import * as fromVitals from "src/app/vitals/store/reducers";
import { SplashScreenComponent } from "./splash-screen/splash-screen.component";
import { InputPopupComponent } from "./patient/input-popup/input-popup.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

import { IoViewComponent } from "./patient/intake-output/io-view/io-view.component";
import { EmrNavTabsComponent } from "./patient/emr-nav-tabs/emr-nav-tabs.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import * as fromDocuments from "src/app/store/reducers/patient-chart/documents/index";
import { SbarFormComponent } from "./patient-list/sbar/sbar-form/sbar-form.component";
import { IoInputComponent } from "./patient/intake-output/io-input/io-input.component";
// import { ConfirmDeleteComponent } from "./shared/confirmDelete/confirmDelete.component";
// import { BradenComponent } from './patient/vitals/vitals-input/braden/braden.component';
// import { ManageUsersComponent } from "./hosp-admin/manage-users/manage-users.component";
import { OrdersViewComponent } from "./patient/orders/orders-view/orders-view.component";
import { PatientHeaderComponent } from "./patient/patient-header/patient-header.component";
import * as fromPatHeader from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromTvUnitView from "src/app/store/reducers/tv-unit-view";
import { OrdersInputComponent } from "./patient/orders/orders-input/orders-input.component";
// import { CensusBillingComponent } from "./hosp-admin/census-billing/census-billing.component";
// import { FallRiskComponent } from './patient/vitals/vitals-input/fall-risk/fall-risk.component';
import { OrderFormComponent } from "./patient/orders/components/order-form/order-form.component";
// import { ManagePatientsComponent } from "./hosp-admin/manage-patients/manage-patients.component";
// import { ManageHospitalsComponent } from "./hosp-admin/manage-hospitals/manage-hospitals.component";
import { OrderPanelComponent } from "./patient/orders/orders-view/order-panel/order-panel.component";
import { DischargeFormComponent } from "./patient/patient-header/discharge-form/discharge-form.component";
import { ProtocolFormComponent } from "./patient/orders/components/protocol-form/protocol-form.component";
import * as fromAuditLineChart from "./store/reducers/auditlineCharts";
// import { VitalsVisionMonitorComponent } from "./vitals/vitals/vitals-vision-monitor/vitals-vision-monitor.component";

import { ChatEffects } from "./patient/chat/store/effects/chat.effects";
// import { ImgCanvasComponent } from "./shared/img-canvas/img-canvas.component";
import { DragulaModule } from "ng2-dragula";
import { VitalModule } from "./vital/vital.module";
import { NgxVentBlankDirective } from "./directives/ngx-vent-blank.directive";

import * as fromMar from "@mar/store/reducers";
import { MarEffects } from "@mar/store/effects/mar.effects";

// import { NurseAuditComponent } from "./auditCharts/nurse-audit/nurse-audit.component";
// import { MomentDateFormatter } from "./auditCharts/customDateFormat";
// import { NurseTableComponent } from "./auditCharts/nurse-table/nurse-table.component";
import { transferRowClickSelection } from "src/app/services/tablerowselectiontransfer";
import { recalculateData } from "src/app/services/recalculateData";
// import { NurseDataComponent } from "./auditCharts/nurse-data/nurse-data.component";
import * as fromRalert from "./ralert/reducers";
import { RalertModule } from "./ralert/ralert.module";
import { GraphModule } from "src/app/graphs/graph.module";
import { NgxPermissionsModule } from "ngx-permissions";
import { PatientMonitorModule } from "./patient-monitor/patient-monitor.module";
import * as fromPatientMonitor from "./patient-monitor/reducers";
import * as fromLabs from "./labs-scans-module/store";
import * as fromNotes from "./note-view-module/store";
import * as fromNote from "@notes/store";
import * as fromViewNote from "@notes-view/store";
import * as sharedState from "@shared-modules/store";
import * as fromNcp from "../app/assessment/nursing-care-plan/store/reducers";
import { ChangeRoleComponent } from "./change-role/change-role.component";
import { AiraModule } from "./aira/aira.module";
import { DatePipe, KeyValuePipe } from "@angular/common";
import { GlobalSearchPatientComponent } from "./utils/global-search-patient/global-search-patient.component";
import { TokenExpiredErrorComponent } from "./token-expired-error/token-expired-error.component";
import { UserEffects } from "./store/effects/user.effects";
import { IrisComponentsModule } from "./iris-components/iris-components.module";
import { OrderableRequestEffects } from "./store/effects/orderable-request.effects";
import { OrderFilterPipe } from "./order-filter.pipe";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { VitalsModule } from "./vitals/vitals.module";
import { SiblingService } from "./services/sibling.service";
import { NoteInputComponent } from "./note-view-module/note-input/note-input.component";
import { SocketDisconnectComponent } from "./socket-disconnect/socket-disconnect.component";
import { IoPrintComponent } from "./patient/intake-output/io-print/io-print.component";
import { FilterLabDatasPipe } from "./patient/summary/filter-lab-datas.pipe";
import { HighlightTextPipe } from "./shared/highlight-text/highlight-text.pipe";
import { MatSidenavModule } from "@angular/material/sidenav";
import { IcuDaysPipeModule } from "./shared-modules/pipes/icu-days-pipe/icu-days-pipe.module";
import { HospFilterPipeModule } from "@shared-modules/pipes/hosp-filter-pipe/hosp-filter-pipe.module";
import { ChatModule } from "@shared-modules/features/chat/chat.module";
import { HandoverNotesModule } from "./handover/handover-notes/handover-notes.module";
import { TextHightlightModule } from "./shared-modules/directives/text-hightlight/text-hightlight.module";
import { InputSideChipModule } from "./iris-components/input-side-chip/input-side-chip.module";
import { NOTE_STATE_NAME } from "@notes/store";
import { NoteInputModule } from "@notes/note-input.module";
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from "@ngrx/router-store";
import { CustomRouterSerializer } from "./store/router/custom-serialize";
import { NotificationModule } from "./notification-module/notification.module";
import { CodeblueModule } from "./codeblue-module/codeblue.module";
import { NcpCreateEffects } from "./assessment/nursing-care-plan/store/effects/ncp.effect";
import { ECallPopupModule } from "@shared-modules/components/e-call-popup/e-call-popup.module";
import { TimezonePipeModule } from "@shared-modules/pipes/timezone-pipe/timezone-pipe.module";
import { FlagIconsModule } from "@shared-modules/components/flag-icons/flag-icons.module";
import { TimezoneLabelModule } from "@shared-modules/components/timezone-label/timezone-label.module";
import { AssignedUsersModule } from "@shared-modules/features/assigned-users/assigned-users.module";
import { StatusChipModule } from "./iris-components/status-chip/status-chip.module";
import { UnitTVViewEffects } from "./store/effects/tv-unit-view.effect";
import { SearchBoxModule } from "@shared-modules/components/search-box";
import Quill from "quill";
import PlainClipboard from "@notes/utils/clipboard";
import { SHARED_STATE_NAME } from "@shared-modules/store";
import { AISocketService } from "src/app/notification-module/services/socket.service";
import { AvatarModule } from "./iris-components/avatar/avatar.module";
import { PatientCardModule } from "@shared-modules/components/patient-card/patient-card.module";
import { NOTE_VIEW_STATE } from "@notes-view/store";
import { ViewImageButtonComponent } from "./view-image-button/view-image-button.component";
import { MeetLinkModule } from "@shared-modules/components/meet-link";
Quill.register(PlainClipboard, true);
import * as patientNotes from "src/app/store/reducers/patient-chart/notes";
// const config: SocketIoConfig = {
//   url: environment.apiUrl,
//   options: {
//     reconnection: true,
//     reconnectionAttempts: 10,
//     reconnectionDelay: 1000,
//     reconnectionDelayMax: 5000,
//     transports: [ 'websocket' ],
//     path: '/CP-EMR',
//     query: `token=${localStorage.getItem('token')}&device_info=WEB`
//   }
// };

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    PatientHeaderComponent,
    EmrNavTabsComponent,
    CpFooterComponent,
    CpHeaderComponent,
    RegisterComponent,
    PageNotFoundComponent,
    DischargeFormComponent,
    OrdersInputComponent,
    OrdersViewComponent,
    OrderPanelComponent,
    NoteInputComponent,
    ScheduleGeneratorPipe,
    AddTimeToDatePipe,
    HighlightTextPipe,
    IoInputComponent,
    IoViewComponent,
    InputPopupComponent,
    SbarFormComponent,
    SplashScreenComponent,
    SummaryComponent,
    // HospAdminComponent,
    LastTagPipe,
    ChangePasswordComponent,
    // ManageHospitalsComponent,
    // CensusBillingComponent,
    // ManageUsersComponent,
    DaysPassedPipe,
    OrderFormComponent,
    // ManagePatientsComponent,
    // VitalsVisionMonitorComponent,
    ProtocolFormComponent,
    // ImgCanvasComponent,
    NgxVentBlankDirective,
    ChangeRoleComponent,
    GlobalSearchPatientComponent,
    TokenExpiredErrorComponent,
    OrderFilterPipe,
    SocketDisconnectComponent,
    IoPrintComponent,
    FilterLabDatasPipe,
    ViewImageButtonComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HandoverNotesModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    OrdersModule,
    NoteInputModule,
    RalertModule,
    PatientMonitorModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    VitalModule,
    // NgxSliderModule,
    FontAwesomeModule,
    IrisComponentsModule,
    AvatarModule,
    StatusChipModule,
    PatientCardModule,
    GraphModule,
    InputSideChipModule,
    DragulaModule.forRoot(),
    ContextMenuModule.forRoot({ autoFocus: true }),

    // components
    ChatModule,
    FlagIconsModule,
    TimezoneLabelModule,

    // pipes
    IcuDaysPipeModule,
    HospFilterPipeModule,
    TimezonePipeModule,

    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: "router" }), // Set up the Router Store
    StoreModule.forFeature("auth", fromAuth.reducer),
    StoreModule.forFeature("patients", fromPatient.reducer),
    StoreModule.forFeature("hospitals", fromHospital.reducer),
    StoreModule.forFeature("units", fromUnit.reducer),
    StoreModule.forFeature("patientHeader", fromPatHeader.reducer),
    StoreModule.forFeature("vitals", fromVitals.reducer),
    // StoreModule.forFeature("notes", fromNotes.reducer),
    StoreModule.forFeature("documents", fromDocuments.reducer),
    StoreModule.forFeature("io", fromIo.reducer),
    StoreModule.forFeature("file", fromFile.reducers),
    StoreModule.forFeature("orderable", fromOrderable.reducers),
    StoreModule.forFeature("protocols", fromProtocol.reducers),
    StoreModule.forFeature("tasks", fromTask.reducers),
    StoreModule.forFeature("auditLineChart", fromAuditLineChart.reducers),
    StoreModule.forFeature("sbarAlert", fromSbarAlerts.reducer),
    // StoreModule.forFeature('tableData',)
    StoreModule.forFeature("notifications", notificationReducer.reducer),
    StoreModule.forFeature("chat", fromChat.reducer),
    StoreModule.forFeature("mar", fromMar.reducer),
    StoreModule.forFeature("ralert", fromRalert.reducers),
    StoreModule.forFeature("patientMonitor", fromPatientMonitor.reducers),
    StoreModule.forFeature("labs", fromLabs.reducers),
    StoreModule.forFeature("notesF", fromNotes.reducer),
    StoreModule.forFeature(NOTE_STATE_NAME, fromNote.reducer),
    StoreModule.forFeature(NOTE_VIEW_STATE, fromViewNote.reducer),
    StoreModule.forFeature("patientNotes", patientNotes.reducer),
    StoreModule.forFeature("tvUnitView", fromTvUnitView.reducers),
    StoreModule.forFeature(SHARED_STATE_NAME, sharedState.SharedReducer),
    QuillModule.forRoot(),
    BrowserAnimationsModule,
    StoreModule.forFeature("ncp", fromNcp.reducer),

    EffectsModule.forRoot([
      AuthEffects,
      PatientListEffects,
      UserEffects,
      HospitalEffects,
      PatientHeaderEffects,
      VitalsEffects,
      NotesEffects,
      DocumentsEffects,
      IoEffects,
      OrderableEffects,
      OrderEffects,
      ProtocolEffects,
      FileEffects,
      SocketEffects,
      ChatEffects,
      ...fromNote.effects,
      ...fromNotes.effects,
      ...fromViewNote.effects,
      // NotebookEffects,
      MessageEffects,
      MarEffects,
      OrderableRequestEffects,
      NcpCreateEffects,
      UnitTVViewEffects,
    ]),
    // SocketIoModule.forRoot(config),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    AiraModule,
    NotificationModule,
    CodeblueModule,
    MatSidenavModule,
    TextHightlightModule,
    ECallPopupModule,
    AssignedUsersModule,
    SearchBoxModule,
    MeetLinkModule,
  ],
  providers: [
    AISocketService,
    // MomentDateFormatter,
    transferRowClickSelection,
    recalculateData,
    KeyValuePipe,
    DatePipe,

    IcuDaysPipeModule,
    HospFilterPipeModule,
    SiblingService,
    { provide: MAT_DATE_LOCALE, useValue: "en-ZA" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    DaysPassedPipe,
    {
      provide: Favicons,
      useClass: BrowserFavicons,
    },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          cp_icon: {
            type: "image/x-icon",
            href: "./assets/images/cpicon.ico",
            isDefault: true,
          },
          chat_icon: {
            type: "image/x-icon",
            href: "./assets/images/cpicon_with_dot.ico",
          },
        },
        // I determine whether or not a random token is auto-appended to the HREF
        // values whenever an icon is injected into the document.
        cacheBusting: true,
      },
    },
    { provide: RouterStateSerializer, useClass: CustomRouterSerializer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
