import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { GetOrderService } from "../io-view/get-order-data";
import { checkCat, checkDrain, checkMinutes } from "./custom-validators";

import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { createRoot } from "react-dom/client";
import { TimeZoneDetails } from "src/app/models/hospital";
import ImageModal from "src/app/react/react-component/ImageModal/imageModal";
import { IoService } from "src/app/services/io.service";
import { TimezoneService } from "src/app/services/timezone.service";
import * as ioActions from "src/app/store/actions/patient-chart/io/io.actions";
import * as fromOrderReducers from "src/app/store/reducers/order/index";
import * as fromIoReducer from "src/app/store/reducers/patient-chart/io/index";
import { bloodProduct } from "../data";

@Component({
  selector: "app-io-input",
  templateUrl: "./io-input.component.html",
  styleUrls: ["./io-input.component.scss"],
})
export class IoInputComponent implements OnInit {
  public getClickedDay$ = this.store.pipe(select(fromIoReducer.getClickedDay));
  public getOutputProps$ = this.store.pipe(
    select(fromIoReducer.getOutputProps)
  );
  public ioForm$ = this.store.pipe(select(fromIoReducer.getIoForm));
  public orders$ = this.store.pipe(select(fromOrderReducers.getOrders));

  public currentPatient;
  public CPMRN;
  public encounters;
  public timeZoneDetail: TimeZoneDetails;
  public _patAdmitDate;
  @ViewChild("reactpopUpmodel", { static: true }) reactpopUpmodel: ElementRef;
  data: any;
  clickedObj: any;
  isVerified: boolean;

  @Input()
  set currPatient(currPatient: any) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
      this.timeZoneDetail =
        this.currentPatient?.timeZoneDetail ||
        this.currentPatient?.hospitalInfo?.timeZoneDetail;
      this._patAdmitDate = this._tz.transformIntoTimezoneObj(
        this.currentPatient["ICUAdmitDate"]
      );

      this.CPMRN = this.currentPatient.CPMRN;
      this.encounters = this.currentPatient.encounters;
    }
  }

  public currentTabIndex = 0;

  public ioProps = {};

  public ioIntakeForm;
  public ioOutputForm;

  public amoutValidatros = [
    Validators.required,
    Validators.max(99999),
    Validators.pattern(/^(\d*\.)?\d+$/),
  ];

  public ioMedCat = {
    name: "Meds",
    propName: "meds",
    sub: [
      {
        name: "Bolus",
        propName: "bolus",
      },
      {
        name: "Infusion",
        propName: "infusion",
      },
    ],
  };

  public ioBloodCat = {
    name: "Blood Products",
    propName: "bloodProducts",
    sub: bloodProduct,
  };

  public ioIntakeObj = [
    {
      name: "Feeds",
      propName: "feeds",
      sub: [
        {
          name: "Oral",
          propName: "oral",
        },
        {
          name: "Breast-feeding",
          propName: "breastfeed",
        },
        {
          name: "Tube",
          propName: "tube",
        },
        {
          name: "TPN/PN",
          propName: "tpnPn",
        },
        {
          name: "Meals",
          propName: "meals",
        },
        {
          name: "Others",
          propName: "others",
        },
      ],
    },
    this.ioMedCat,
    this.ioBloodCat,
    {
      name: "Others",
      propName: "others",
      sub: [],
    },
  ];

  public inSubCat: any = [];

  public ioOutputObj = [
    {
      name: "Urine",
      propName: "urine",
    },
    {
      name: "Drain",
      propName: "drain",
    },
    {
      name: "Procedure",
      propName: "procedure",
    },
    {
      name: "Dialysis",
      propName: "dialysis",
    },
    {
      name: "Stool",
      propName: "stool",
    },
    {
      name: "Emesis",
      propName: "emesis",
    },
    {
      name: "NG Aspirate",
      propName: "NG_aspirate",
    },
    {
      name: "Others",
      propName: "others",
    },
  ];

  public isReadOnly = true;

  public restErrorIntake = false;
  public disableBtnIntake = null;
  public restErrorOutput = false;
  public disableBtnOutput = null;

  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  faCalendarAlt = faCalendarAlt;
  faSpinner = faSpinner;
  imageKey?: string;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<{}>,
    private _getOrderService: GetOrderService,
    private _ioService: IoService
  ) {}

  activeMeds = [];

  private _tz = inject(TimezoneService);

  ngOnInit() {
    // Set up fb for intake
    this.ioIntakeForm = this.fb.group(
      {
        category: ["", Validators.required],
        subCategory: [""],
        otherFeeds: [""],
        medName: [""],
        otherSub: [""],
        amount: ["", this.amoutValidatros],
        note: [""],
        date: ["", Validators.required],
      },
      { validator: checkCat }
    );

    // Set up fb for output
    this.ioOutputForm = this.fb.group(
      {
        category: ["", Validators.required],
        otherSub: [""],
        amount: ["", this.amoutValidatros],
        note: [""],
        date: ["", Validators.required],
        name: [""],
        site: [""],
        laterality: [""],
        drainType: [""],
        procedureType: [""],
        dialysisType: [""],
      },
      { validator: checkDrain }
    );

    // listen to date change
    this.changeResponses();

    // Notify the click
    this.listenToClick();

    // Notify when props updates
    this.listenToProps();

    // Notify when update
    this.listenToDataUpdate();

    // get active orders
    this.getActiveOrders();
  }

  public setAmtValidators(selected: string): void {
    if (selected == "breastfeed")
      this.inAmount.setValidators([...this.amoutValidatros, checkMinutes()]);
    else {
      this.inAmount.setValidators([...this.amoutValidatros]);
    }
    this.inAmount.updateValueAndValidity();
  }

  getActiveOrders(): void {
    this.orders$.subscribe((data) => {
      // get active orders
      this.activeMeds = data["active"]["medications"].map((order) => {
        let updatedOrder = {
          name: `${order.name}-${order.quantity}${order.unit}-${order.route}`,
        };

        if (order.combination?.length) {
          let name = order.combination.reduce((acc, combiMed) => {
            acc = acc + "-" + combiMed.name;

            return acc;
          }, order.name);

          updatedOrder = { name };
        }

        return updatedOrder;
      });
    });
  }

  changeResponses() {
    this.inDate.valueChanges.subscribe((dateTime) => {
      this.onTimeDateChanged({
        date: this.inDate.value,
        time: this.getIOTime(dateTime),
      });
    });
    this.otDate.valueChanges.subscribe((dateTime) => {
      this.onTimeDateChanged({
        date: this.otDate.value,
        time: this.getIOTime(dateTime),
      });
    });
  }

  getIOTime(dateTime): string {
    const dateTimeObj = this._tz.transformIntoTimezoneObj(dateTime),
      HH = dateTimeObj.hour(),
      MM = dateTimeObj.minute();
    return `${HH}:${MM}`;
  }

  // Setup the subtype
  setInSubCat(obj) {
    // let selOptIndex: number = obj.target.selectedIndex - 1;
    const subCatValue = obj.value;
    // get the value for sub
    const subCategoryObj = this.ioIntakeObj.filter((subElement) => {
      if (subElement.propName == subCatValue) return subElement;
    });
    // reset the sub
    this.resetIoForm("intake");

    // set the value for sub
    this.inSubCat = subCategoryObj[0].sub;
  }

  // reset the subtype
  resetIoForm(type) {
    if (type == "output") {
      this.otName.setValue("");
      this.otSite.setValue("");
      this.otLat.setValue("");
      this.otOther.setValue("");
      this.isReadOnly = true;
    } else if (type == "intake") {
      this.inSub.setValue("");
      this.ioIntakeForm.get("otherSub").setValue("");
      this.ioIntakeForm.get("medName").setValue("");
      this.inSub.markAsUntouched();
    }
  }

  resetFormUi(type) {
    if (type == "intake") {
      this.disenaSelFields("enable");
      this.ioIntakeForm.reset();
      this.ioIntakeForm.markAsPristine();

      this.resetInactiveMed();
    } else if (type == "output") {
      this.ioOutputForm.reset();
      this.ioOutputForm.markAsPristine();
    }
  }

  // Submit form
  submitIoData(obj, type, data) {
    this.store.dispatch(ioActions.submitForm({ loadType: type }));

    // manually set the value
    if (type == "intake") {
      obj.category = this.ioIntakeForm.get("category").value;
      obj.subCategory = this.ioIntakeForm.get("subCategory").value;
      obj.medName = this.ioIntakeForm.get("medName").value;
    }

    obj.image = this.clickedObj?.image
      ? { ...this.clickedObj.image, isVerified: true }
      : null;

    console.log("Image object:", obj.image);
    const dateObject = this._tz.transformIntoTimezoneObj(obj["date"]);
    obj = { ...obj, time: this.getIOTime(dateObject) };
    obj["dayNum"] = this._getOrderService.calcDayNumber(
      this._patAdmitDate,
      dateObject
    ); // send day num from client
    obj.date = this._tz.transformIntoTimezoneObj(obj.date).valueOf();
    this.store.dispatch(
      ioActions.updateIo({
        obj,
        CPMRN: this.CPMRN,
        ioType: type,
        encounters: this.encounters,
      })
    );
  }

  // reset the added inactive med
  resetInactiveMed(): void {
    if (this.newMedAdded) {
      this.activeMeds.pop();
      this.newMedAdded = false;
    }
  }

  // Listen to click
  newMedAdded: boolean = false;
  listenToClick() {
    this.getClickedDay$.subscribe((data) => {
      this.clickedObj = data?.["clickedObj"];
      this.isVerified = true;
      if (data && data["catInfo"]) {
        if (data["catInfo"]["process"] == "intake") {
          if (data["clickedObj"]["orders"] || data["clickedObj"]["edited"]) {
            this.disenaSelFields("disable");
          } else {
            this.disenaSelFields("enable");
          }
          // select tab
          // this.tabs.select('intake');
          this.currentTabIndex = 0;

          // set sub cat
          for (let day of this.ioIntakeObj) {
            if (day.propName == data["catInfo"]["cat"]) {
              this.inSubCat = day.sub;
              break;
            }
          }

          let medName = data["clickedObj"]["name"];
          let foundMed = this.activeMeds.findIndex(
            (med) => med.name === medName
          );
          if (foundMed < 0) {
            this.activeMeds.push({ name: medName });
            this.newMedAdded = true;
          }
          this.setAmtValidators(data["catInfo"]["sub"]);
          this.ioIntakeForm.patchValue({
            category: data["catInfo"]["cat"],
            subCategory: data["catInfo"]["sub"],
            amount: data["clickedObj"]["amount"],
            note: data["clickedObj"]["note"],
            medName: data["clickedObj"]["name"],
            otherFeeds: data["clickedObj"]["name"],
            otherSub: data["clickedObj"]["name"],
            date: this.getDateTime({
              date: data["date"],
              hours: data["hourName"],
              minutes: data["minName"],
            }),
          });
        } else if (data["catInfo"]["process"] == "output") {
          // select tab
          // this.tabs.select('output');
          this.imageKey = data?.["clickedObj"]?.["image"]?.["key"];
          this.currentTabIndex = 1;

          this.ioOutputForm.patchValue({
            category: data["catInfo"]["cat"],
            otherSub: data["clickedObj"]["name"],
            drainType:
              data["clickedObj"]["name"] +
              "-" +
              data["clickedObj"]["site"] +
              "-" +
              data["clickedObj"]["laterality"],
            procedureType:
              data["clickedObj"]["name"] + "-" + data["clickedObj"]["site"],
            dialysisType: data["clickedObj"]["name"],
            name: data["clickedObj"]["name"],
            site: data["clickedObj"]["site"],
            laterality: data["clickedObj"]["laterality"],
            amount: data["clickedObj"]["amount"],
            note: data["clickedObj"]["note"],
            date: this.getDateTime({
              date: data["date"],
              hours: data["hourName"],
              minutes: data["minName"],
            }),
          });
        }
      }
    });
  }

  // Setting min and max date
  get matMinDate() {
    const admitDate = this.currentPatient?.ICUAdmitDate;
    if (!admitDate) return new Date(1900, 0, 1);
    return this._tz.transformIntoTimezoneObj(admitDate);
  }

  get matMaxDate() {
    const dischargeDate = this.currentPatient?.ICUDischargeDate;
    if (!dischargeDate) return this._tz.getCurrentTimeObj();
    return this._tz.transformIntoTimezoneObj(dischargeDate);
  }

  // listen to data update
  listenToDataUpdate() {
    // listen to loading props
    let formType;
    this.ioForm$.subscribe((data) => {
      this.formState = data;

      // set form type
      if (this.formState.loadType) formType = this.formState.loadType;

      if (this.formState.showSuccess) {
        if (formType == "intake") {
          this.disenaSelFields("enable");
          this.ioIntakeForm.reset();
          this.ioIntakeForm.markAsPristine();
          this.ioIntakeForm.markAsUntouched();

          this.resetInactiveMed();
        } else if (formType == "output") {
          this.ioOutputForm.reset();
          this.isReadOnly = true;
          this.ioOutputForm.markAsPristine();
          this.ioOutputForm.markAsUntouched();
        }
      }
    });
  }

  // disable / enable selected fields
  disenaSelFields(action) {
    if (action == "disable") {
      this.ioIntakeForm.controls["category"].disable();
      this.ioIntakeForm.controls["subCategory"].disable();
      this.ioIntakeForm.controls["medName"].disable();

      // this.ioIntakeObj.push(this.ioBloodCat);
      // this.ioIntakeObj.push(this.ioMedCat);
    } else {
      this.ioIntakeForm.controls["category"].enable();
      this.ioIntakeForm.controls["subCategory"].enable();
      this.ioIntakeForm.controls["medName"].enable();

      // let elemsToRemove = [];
      // this.ioIntakeObj.forEach((obj, index) => {
      //   if (obj.propName == "meds" || obj.propName == "bloodProducts") {
      //     elemsToRemove.push(index);
      //   }
      // });

      // for (let i = elemsToRemove.length - 1; i >= 0; i--) {
      //   this.ioIntakeObj.splice(elemsToRemove[i], 1);
      // }

      // reset sub cat
      this.inSubCat = [];
    }
  }

  // listen to props change
  listenToProps() {
    this.getOutputProps$.subscribe((data) => {
      this.ioProps = data;
    });
  }

  // ouput field change
  opPropChanged(event, type) {
    let val = event.value;

    if (val == "add") {
      this.isReadOnly = false;
    } else {
      this.isReadOnly = true;

      this.ioProps[type].forEach((element) => {
        switch (type) {
          case "drain":
            if (
              val ==
              element.name + "-" + element.site + "-" + element.laterality
            ) {
              this.otName.setValue(element.name);
              this.otSite.setValue(element.site);
              this.otLat.setValue(element.laterality);
            }
            break;

          case "procedure":
            if (val == element.name + "-" + element.site) {
              this.otName.setValue(element.name);
              this.otSite.setValue(element.site);
            }
            break;

          case "dialysis":
            if (val == element.name) {
              this.otName.setValue(element.name);
            }
            break;
        }
      });
    }
  }

  // on time change
  onTimeDateChanged(dateObj) {
    this.store.dispatch(ioActions.setChangedTime({ dateObj }));
  }

  // Get date and time
  public getDateTime({ date, hours, minutes }): any {
    if (!date) return null;
    const fullHour = hours.length == 1 ? "0" + hours : hours,
      fullMinutes = minutes.length == 1 ? "0" + minutes : minutes,
      dateConv = this._tz.transformIntoTimezoneObj(date),
      fullYear = dateConv.year(),
      month = dateConv.month() + 1,
      day = dateConv.date(),
      dateConvDate = fullYear + "-" + month + "-" + day,
      returnDate = this._tz.transformIntoTimezoneObj(dateConvDate);
    return returnDate
      .hour(fullHour)
      .minute(fullMinutes)
      .second(0)
      .toISOString();
  }

  public getAmtUnit(): string {
    if (this.inSub.value == "meals") return "quantity";
    if (this.inSub.value == "breastfeed") return "min";
    return "ml";
  }

  ngOnDestroy() {}

  // Getter methods for intake form controls
  get inCat() {
    return this.ioIntakeForm.get("category");
  }
  get inSub() {
    return this.ioIntakeForm.get("subCategory");
  }
  get inAmount() {
    return this.ioIntakeForm.get("amount");
  }
  get inNote() {
    return this.ioIntakeForm.get("note");
  }
  get inDate() {
    return this.ioIntakeForm.get("date");
  }

  // Getter methods for output form controls
  get otCat() {
    return this.ioOutputForm.get("category");
  }
  get otName() {
    return this.ioOutputForm.get("name");
  }
  get otSite() {
    return this.ioOutputForm.get("site");
  }
  get otLat() {
    return this.ioOutputForm.get("laterality");
  }
  get otAmount() {
    return this.ioOutputForm.get("amount");
  }
  get otDate() {
    return this.ioOutputForm.get("date");
  }
  get otOther() {
    return this.ioOutputForm.get("otherSub");
  }

  // prefill output
  fillInputScreen(date, hourName, minName, catProps, clickedObj) {
    let data = {
      date: this._tz.transformIntoTimezoneObj(date),
      hourName: hourName,
      minName: minName,
      catInfo: catProps,
      clickedObj: clickedObj,
    };

    this.store.dispatch(ioActions.setClickedTime({ day: data }));
  }

  private renderReactComponent(param: {
    CPMRN: string;
    imageSrc: string;
  }): void {
    const root = createRoot(this.reactpopUpmodel.nativeElement);

    root.render(
      React.createElement(ImageModal, {
        CPMRN: param.CPMRN,
        imageSrc: param.imageSrc,
      })
    );
  }

  openReactImageModel(param: { CPMRN: string; key: string }) {
    if (!param.key) return;
    this._ioService
      .downloadImage({
        CPMRN: this.currentPatient.CPMRN,
        key: param.key,
      })
      .subscribe({
        next: (body: any) => {
          const imageSrc = body?.data;
          if (imageSrc) {
            this.renderReactComponent({
              CPMRN: this.currentPatient.CPMRN,
              imageSrc: imageSrc,
            });
          } else {
          }
        },

        error: (err) => {},
      });
  }
}
