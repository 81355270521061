import { bloodProduct } from "../data";

/**
 * value of `this` will be the timezone service
 * @param ioObj
 * @param strDateObj
 * @param endDateObj
 * @returns
 */
export function getNetReport(
  ioObj,
  strDateObj,
  endDateObj,
  isAdultPatient: boolean = true
) {
  // calc the start date
  let strDate =
    strDateObj.date() +
    "-" +
    (strDateObj.month() + 1) +
    "-" +
    strDateObj.year();
  let strtHour = strDateObj.hour();

  // calc the now date
  let endDate =
    endDateObj.date() +
    "-" +
    (endDateObj.month() + 1) +
    "-" +
    endDateObj.year();
  let endHour = endDateObj.hour();

  // calc the difference in hours
  let hrAmount = (endDateObj.valueOf() - strDateObj.valueOf()) / 3.6e6;

  // get the day duration
  let reportArray = [];

  for (let i = 0; i <= ioObj.length - 1; i++) {
    let tempDay = JSON.parse(JSON.stringify(ioObj[i]));
    let ioDay = this.startOfDay(tempDay.dayDate);
    let ioDate = ioDay.date() + "-" + (ioDay.month() + 1) + "-" + ioDay.year();

    strDateObj = this.startOfDay(strDateObj);
    endDateObj = this.startOfDay(endDateObj);

    if (
      ioDay.valueOf() >= strDateObj.valueOf() &&
      ioDay.valueOf() <= endDateObj.valueOf()
    ) {
      if (ioDate == strDate) {
        let tempSplices = [];
        tempDay.hours.forEach((hour, h) => {
          if (hour.hourName < strtHour) {
            tempSplices.push(h);
          }
        });

        for (let i = tempSplices.length - 1; i >= 0; i--) {
          tempDay.hours.splice(tempSplices[i], 1);
        }
      }
      if (ioDate == endDate) {
        let tempSplices = [];
        tempDay.hours.forEach((hour, h) => {
          if (hour.hourName > endHour) {
            tempSplices.push(h);
          }
        });

        for (let i = tempSplices.length - 1; i >= 0; i--) {
          tempDay.hours.splice(tempSplices[i], 1);
        }
      }

      reportArray.push(tempDay);
    }
  }

  let intakeReport = 0;
  let outputReport = 0;
  let netReport = 0;

  let avgUrine = 0;

  reportArray.forEach((day) => {
    day.hours.forEach((hour) => {
      intakeReport +=
        getSumOfObjs(hour?.hourTotal?.intake?.feeds, ["breastfeed"]) +
        getSumOfObjs(hour?.hourTotal?.intake?.bloodProducts) +
        getSumOfObjs(hour?.hourTotal?.intake?.bolus) +
        getSumOfObjs(hour?.hourTotal?.intake?.infusion) +
        hour?.hourTotal?.intake?.others?.val;

      outputReport +=
        (hour.hourTotal.output?.NG_aspirate?.val ?? 0) +
        getSumOfObjs(hour.hourTotal.output?.drain ?? []) +
        getSumOfObjs(hour.hourTotal.output?.procedure ?? []) +
        getSumOfObjs(hour.hourTotal.output?.dialysis ?? []) +
        (hour.hourTotal.output?.others?.val ?? 0);

      hour.minutes.forEach((minute) => {
        if (
          (minute?.output?.urine?.image &&
            minute.output.urine.image.isVerified) ||
          !minute?.output?.urine?.image
        ) {
          const urineAmount = minute?.output?.urine?.amount ?? 0;
          outputReport += urineAmount;
          avgUrine += urineAmount;
        }
      });
    });
  });

  netReport = intakeReport - outputReport;
  return {
    inReport: convertTotalToProperUnit(intakeReport, hrAmount, isAdultPatient),
    outReport: convertTotalToProperUnit(outputReport, hrAmount, isAdultPatient),
    netReport: convertTotalToProperUnit(netReport, hrAmount, isAdultPatient),
    avgUrine: roundOff(avgUrine / hrAmount),
  };
}

/**
 * @description used to convert totalIntake amount to proper unit
 * -- for adult it will be in ml
 * -- for NICU and PICU it will be in ml/hr
 * @param totalAmount
 * @param hrAmount
 * @param isAdultPatient
 * @author Rajat Saini
 * @date Feb 28, 2024
 */
export const convertTotalToProperUnit = (
  totalAmount,
  hrAmount,
  isAdultPatient
) => {
  if (isAdultPatient) return roundOff(totalAmount);

  return roundOff(totalAmount / hrAmount);
};

const roundOff = (val) => Math.round(val * 100) / 100;

export function getSumOfObjs(obj, exludeKeys = []) {
  let total = 0;
  for (let o in obj) {
    if (typeof obj[o] === "object") {
      const addValue = exludeKeys.includes(o) ? 0 : obj[o]["val"];
      total += addValue;
    }
  }

  return total;
}

// calculate the total of all properties
export function calcTotalProperties(ioDay, totalNeeded, props) {
  let feedsTemp = [];
  let bloodTemp = [];
  let medsTemp = [];
  let infTemp = [];
  let bolTemp = [];
  let drainsTemp = [];
  let procedureTemp = [];
  let dialysisTemp = [];

  let inTemp = [];
  let outTemp = [];

  let totalIntake = 0;
  let totalOutput = 0;

  // let blood = bloodProduct.reduce((acc, blood) => {
  //   acc[blood.propName] = {count: 0,val: 0}
  //   return acc;
  // }, {})

  // set total object for each hour
  ioDay["hours"].forEach((hr) => {
    // get total object for each hour
    hr["hourTotal"] = {
      intake: {
        feeds: {
          oral: { count: 0, val: 0 },
          breastfeed: { count: 0, val: 0 },
          tube: { count: 0, val: 0 },
          tpnPn: { count: 0, val: 0 },
          meals: { count: 0, val: 0 },
          others: { count: 0, val: 0 },
        },
        bloodProducts: bloodProduct.reduce((acc, blood) => {
          acc[blood.propName] = { count: 0, val: 0 };
          return acc;
        }, {}),
        infusion: {},
        bolus: {},
        others: { count: 0, val: 0 },
      },
      output: {
        urine: { count: 0, val: 0 },
        stool: { count: 0, val: 0 },
        emesis: { count: 0, val: 0 },
        NG_aspirate: { count: 0, val: 0 },
        others: { count: 0, val: 0 },
        drain: {},
        procedure: {},
        dialysis: {},
      },
    };

    // set inf and bolus props
    props.bolusProps.forEach((med) => {
      hr["hourTotal"]["intake"]["bolus"][med] = {
        count: 0,
        val: 0,
      };
    });
    props.infusionProps.forEach((med) => {
      hr["hourTotal"]["intake"]["infusion"][med] = {
        count: 0,
        val: 0,
      };
    });

    // set drains, procedure and dialysis props
    Object.keys(props.ioProps).forEach((key) => {
      props.ioProps[key].forEach((elem) => {
        let propToAdd = elem.name;
        if (elem.site) {
          propToAdd += "-" + elem.site;
        }
        if (elem.laterality) {
          propToAdd += "-" + elem.laterality;
        }
        hr["hourTotal"]["output"][key][propToAdd] = {
          count: 0,
          val: 0,
        };
      });
    });

    // set the io props for loop
    let ioProps = [
      {
        name: "intake",
        props: [
          {
            name: "feeds",
            props: Object.keys(hr["hourTotal"]["intake"]["feeds"]),
          },
          {
            name: "bloodProducts",
            props: Object.keys(hr["hourTotal"]["intake"]["bloodProducts"]),
          },
          {
            name: "infusion",
            props: props.infusionProps,
          },
          {
            name: "bolus",
            props: props.bolusProps,
          },
          {
            name: "others",
          },
        ],
      },
      {
        name: "output",
        props: [
          { name: "urine" },
          { name: "stool" },
          { name: "emesis" },
          { name: "NG_aspirate" },
          { name: "others" },
          {
            name: "drain",
            props: props.ioProps["drain"],
          },
          {
            name: "procedure",
            props: props.ioProps["procedure"],
          },
          {
            name: "dialysis",
            props: props.ioProps["dialysis"],
          },
        ],
      },
    ];

    // create the hrTotal object
    hr.minutes.forEach((minute) => {
      ioProps.forEach((ioElem) => {
        if (minute[ioElem["name"]]) {
          ioElem["props"].forEach((prop1) => {
            if (minute[ioElem["name"]][prop1["name"]]) {
              if (prop1["props"]) {
                prop1["props"].forEach((prop2) => {
                  if (
                    prop1["name"] == "drain" ||
                    prop1["name"] == "procedure" ||
                    prop1["name"] == "dialysis"
                  ) {
                    let str = "";
                    if (prop1["name"] == "drain") {
                      str =
                        prop2["name"] +
                        "-" +
                        prop2["site"] +
                        "-" +
                        prop2["laterality"];
                      minute["output"]["drain"].forEach((drain) => {
                        if (
                          drain["name"] == prop2["name"] &&
                          drain["site"] == prop2["site"] &&
                          drain["laterality"] == prop2["laterality"]
                        ) {
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "val"
                          ] += parseFloat(drain["amount"]);
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "count"
                          ]++;
                        }
                      });
                    }
                    if (prop1["name"] == "procedure") {
                      str = prop2["name"] + "-" + prop2["site"];
                      minute["output"]["procedure"].forEach((procedure) => {
                        if (
                          procedure["name"] == prop2["name"] &&
                          procedure["site"] == prop2["site"]
                        ) {
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "val"
                          ] += parseFloat(procedure["amount"]);
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "count"
                          ]++;
                        }
                      });
                    }
                    if (prop1["name"] == "dialysis") {
                      str = prop2["name"];
                      minute["output"]["dialysis"].forEach((dialysis) => {
                        if (dialysis["name"] == prop2["name"]) {
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "val"
                          ] += parseFloat(dialysis["amount"]);
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "count"
                          ]++;
                        }
                      });
                    }
                  } else {
                    if (minute[ioElem["name"]][prop1["name"]][prop2]) {
                      hr["hourTotal"][ioElem["name"]][prop1["name"]][prop2][
                        "val"
                      ] += parseFloat(
                        minute[ioElem["name"]][prop1["name"]][prop2]["amount"]
                      );
                      hr["hourTotal"][ioElem["name"]][prop1["name"]][prop2][
                        "count"
                      ]++;
                    }
                  }
                });
              } else {
                if (minute[ioElem["name"]][prop1["name"]]) {
                  hr["hourTotal"][ioElem["name"]][prop1["name"]]["val"] +=
                    parseFloat(minute[ioElem["name"]][prop1["name"]]["amount"]);
                  hr["hourTotal"][ioElem["name"]][prop1["name"]]["count"]++;
                }
              }
            } else if (minute["intake"]["meds"][prop1["name"]]) {
              minute["intake"]["meds"][prop1["name"]].forEach((med) => {
                prop1["props"].forEach((propMed) => {
                  if (med.name == propMed) {
                    hr["hourTotal"]["intake"][prop1["name"]][propMed]["val"] +=
                      parseFloat(med["amount"]);
                    hr["hourTotal"]["intake"][prop1["name"]][propMed][
                      "count"
                    ]++;
                  }
                });
              });
            }
          });
        }
      });
    });
  });

  // get the total
  if (totalNeeded) {
    ioDay["hours"].forEach((hour, index) => {
      let urineOutput = 0;

      hour.minutes.forEach((minute) => {
        if (
          !minute?.output?.urine?.image ||
          minute?.output?.urine?.image?.isVerified
        ) {
          urineOutput += minute?.output?.urine?.amount;
        }
      });

      feedsTemp.push(
        getSumOfObjs(hour["hourTotal"]["intake"]["feeds"], ["breastfeed"])
      );
      bloodTemp.push(
        getSumOfObjs(hour["hourTotal"]["intake"]["bloodProducts"])
      );
      infTemp.push(getSumOfObjs(hour["hourTotal"]["intake"]["infusion"]));
      bolTemp.push(getSumOfObjs(hour["hourTotal"]["intake"]["bolus"]));

      drainsTemp.push(getSumOfObjs(hour["hourTotal"]["output"]["drain"]));
      procedureTemp.push(
        getSumOfObjs(hour["hourTotal"]["output"]["procedure"])
      );
      dialysisTemp.push(getSumOfObjs(hour["hourTotal"]["output"]["dialysis"]));

      medsTemp.push(infTemp[index] + bolTemp[index]);

      inTemp.push(
        feedsTemp[index] +
          bloodTemp[index] +
          medsTemp[index] +
          hour["hourTotal"]["intake"]["others"]["val"]
      );
      outTemp.push(
        drainsTemp[index] +
          procedureTemp[index] +
          dialysisTemp[index] +
          urineOutput +
          hour["hourTotal"]["output"]["NG_aspirate"]["val"] +
          hour["hourTotal"]["output"]["others"]["val"]
      );
    });

    totalIntake = (inTemp.reduce((sum, val) => sum + val) * 100) / 100;
    totalOutput =
      Math.round(outTemp.reduce((sum, val) => sum + val) * 100) / 100;
  }

  return {
    feedsTemp: feedsTemp,
    bloodTemp: bloodTemp,
    infTemp: infTemp,
    bolTemp: bolTemp,
    medsTemp: medsTemp,
    drainsTemp: drainsTemp,
    procedureTemp: procedureTemp,
    dialysisTemp: dialysisTemp,
    inTemp: inTemp,
    outTemp: outTemp,
    totalIntake: totalIntake,
    totalOutput: totalOutput,
    ioDay: ioDay,
  };
}
