import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { ioObj } from "src/app/models/io";
import { calcTotalProperties, getNetReport } from "./get-net-report";

import { UntypedFormBuilder } from "@angular/forms";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDown,
  faCheck,
  faPrescriptionBottleAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Alert } from "@iris/model/iris-component.model";
import { AlertService } from "@iris/service/alert.service";
import { TimezonePipe } from "@shared-modules/pipes/timezone-pipe/timezone.pipe";
import { Moment } from "moment-timezone";
import React from "react";
import { createRoot } from "react-dom/client";
import { LabsScansService } from "src/app/labs-scans-module/services/labs-scans.service";
import { TimeZoneDetails } from "src/app/models/hospital";
import { PatientType } from "src/app/models/patient";
import ImageModal from "src/app/react/react-component/ImageModal/imageModal";
import { DateChangeService } from "src/app/services/date-change.service";
import { IoService } from "src/app/services/io.service";
import { TimezoneService } from "src/app/services/timezone.service";
import * as ioActions from "src/app/store/actions/patient-chart/io/io.actions";
import * as fromIoReducer from "src/app/store/reducers/patient-chart/io/index";
import {
  CALCULATE_CURRENT_WEIGHT,
  calculateCurrentWeight,
} from "src/app/support-functions/calculateWeight";
import { CpContextMenuService } from "../../../services/cp-context-menu.serivce";
import { bloodProduct } from "../data";
@Component({
  selector: "app-io-view",
  templateUrl: "./io-view.component.html",
  styleUrls: ["./io-view.component.scss"],
})
export class IoViewComponent implements OnInit {
  private _tz = inject(TimezoneService);

  @ViewChild("reactpopUpmodel", { static: true }) reactpopUpmodel: ElementRef;

  public getIo$ = this.store.pipe(select(fromIoReducer.getIoData));
  public getChangedTime$ = this.store.pipe(
    select(fromIoReducer.getChangedTime)
  );
  public ioForm$ = this.store.pipe(select(fromIoReducer.getIoForm));
  public CPMRN;
  public encounters;
  // Selected date
  selectedDate: Moment = this._tz.getCurrentTimeObj();
  // Hide show variables
  public accordianProperty = {
    showFeed: false,
    showBlood: false,
    showMed: false,
    showInfusion: false,
    showBolus: false,
    showDrain: false,
    showProcedure: false,
    showDialysis: false,
  };
  public currentPatient;
  public timeZoneDetail: TimeZoneDetails;
  public icuAdmitDate;
  public ioProps = {};
  public infusionProps = [];
  public bolusProps = [];
  public ioObj: [ioObj];
  public ioDay = {};
  public ioDayIndex = 0;
  // total IO
  public intakeTotal = 0;
  public inPerHr = [];
  public inFeeds = [];
  public inBlood = [];
  public inMeds = [];
  public inInf = [];
  public inBol = [];
  public outputTotal = 0;
  public outPerHr = [];
  public otDrain = [];
  public otProcedure = [];
  public otDialysis = [];

  // Reports
  public ioReport = {
    showReport: false,
    intakeReport: 0,
    outputReport: 0,
    netReport: 0,
    avgUrine: 0,
  };
  faAngleDown = faAngleDown;
  faPrescriptionBottleAlt = faPrescriptionBottleAlt;
  faCalendarAlt = faCalendarAlt;
  faCheck = faCheck;
  timeZone = new TimezonePipe();
  bloodStructure: { propName: string; name: string }[] = bloodProduct;

  /**
   * @name isReportFieldChange
   * @desc Theis field is needed to know whether the data is available to print
   */
  isReportFieldChange = false;

  // Reactive Forms
  ioReportTimeRange = this.fb.group({
    from: [this.getFromDefaultValue()],
    to: [this.getToDefaultValue()],
  });

  @Input() currUser;
  openImageModel: boolean = false;

  @Input()
  set currPatient(currPatient: any) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
      this.timeZoneDetail =
        this.currentPatient?.timeZoneDetail ||
        this.currentPatient?.hospitalInfo?.timeZoneDetail;
      this.CPMRN = this.currentPatient.CPMRN;
      this.encounters = this.currentPatient.encounters;
      this.icuAdmitDate = this.currentPatient.ICUAdmitDate;
      this.currentWeight = this.calculateCurrW();
      this.currentPatient["currentWeight"] = this.currentWeight;
    }
  }

  currentWeight: any;
  patientType = PatientType;

  constructor(
    private store: Store<{}>,
    private cpContextMenuService: CpContextMenuService,
    private datechange: DateChangeService,
    private fb: UntypedFormBuilder,
    private _labScanService: LabsScansService,
    private _alertService: AlertService,
    private _ioService: IoService
  ) {}

  ngAfterViewInit(): void {}

  private renderReactComponent(param: {
    CPMRN: string;
    imageSrc: string;
  }): void {
    const root = createRoot(this.reactpopUpmodel.nativeElement);

    root.render(
      React.createElement(ImageModal, {
        CPMRN: param.CPMRN,
        imageSrc: param.imageSrc,
      })
    );
  }

  openReactImageModel(param: { CPMRN: string; key: string }) {
    if (!param.key) return;
    this._ioService
      .downloadImage({
        CPMRN: this.currentPatient.CPMRN,
        key: param.key,
      })
      .subscribe({
        next: (body: any) => {
          const imageSrc = body?.data;
          if (imageSrc) {
            this.renderReactComponent({
              CPMRN: this.currentPatient.CPMRN,
              imageSrc: imageSrc,
            });
          } else {
          }
        },

        error: (err) => {},
      });
  }

  ngOnInit() {
    // default report to 12 hrs
    this.reportVal = "12";

    this.getIo();

    this.notifyUpdate();

    this.notifyIoInputTimeChange();

    this.reportFromTime?.valueChanges?.subscribe(
      (_) => (this.isReportFieldChange = true)
    );
    this.reportToTime?.valueChanges?.subscribe(
      (_) => (this.isReportFieldChange = true)
    );
  }

  calculateCurrW() {
    const payload: CALCULATE_CURRENT_WEIGHT = {
      weightHistory: this.currentPatient?.weightHistory,
      weightObj: this.currentPatient?.weightObj,
      patientType: this.currentPatient?.patientType,
      weightInGrams: false,
      addUnits: false,
      weight: this.currentPatient?.weight,
    };
    return calculateCurrentWeight(payload);
  }
  //Get From Time For Report
  get reportFromTime() {
    return this.ioReportTimeRange.get("from");
  }

  //Get To Time For Report
  get reportToTime() {
    return this.ioReportTimeRange.get("to");
  }

  // get IO data
  getIo() {
    this.getIo$.subscribe(
      (io) => {
        if (io["ioObj"].length) {
          this.doIoMath(io);
          this.getReport();
        }
      },
      (err) => {
        // console.log('Server error');
      }
    );
  }

  // Setting min and max date
  get matMinDate() {
    if (this.icuAdmitDate)
      return this._tz.transformIntoTimezoneObj(this.icuAdmitDate);

    return new Date(1900, 0, 1);
  }

  get matMaxDate() {
    return this._tz.getCurrentTimeObj();
  }

  // Get default Value
  getFromDefaultValue() {
    const currentDate = this._tz.getCurrentTimeObj(),
      year = currentDate.year(),
      month = currentDate.month(),
      day = currentDate.date() - 1,
      hour = 8;
    return this._tz
      .transformIntoTimezoneObj(year + "-" + month + "-" + day)
      .hour(hour)
      .toISOString();
  }

  getToDefaultValue() {
    const currentDate = this._tz.getCurrentTimeObj(),
      year = currentDate.year(),
      month = currentDate.month(),
      day = currentDate.date(),
      hour = 8;
    return this._tz
      .transformIntoTimezoneObj(year + "-" + month + "-" + day)
      .hour(hour)
      .toISOString();
  }

  // use the io data
  doIoMath(data) {
    this.ioObj = data["ioObj"];
    this.ioProps = data["outputProps"];
    this.infusionProps = data["intakeProps"]["infusionProps"];
    this.bolusProps = data["intakeProps"]["bolusProps"];

    if (this.ioObj.length > 0) {
      this.setDay(this.ioObj[this.ioDayIndex], this.ioDayIndex);
    }
  }

  // notify time changed
  notifyIoInputTimeChange() {
    this.getChangedTime$.subscribe((data) => {
      if (data) {
        let inTime = data["time"] ? data["time"].split(":") : [];

        let inDate = this._tz.startOfDay(data["date"]);

        let admitDate = this._tz.startOfDay(this.icuAdmitDate);
        let dayNumber = Math.ceil(
          (inDate.valueOf() - admitDate.valueOf()) / 8.64e7
        );

        if (this.ioObj && this.ioObj.length) {
          this.ioObj.forEach((day, index) => {
            if (day.dayNumber == dayNumber + 1) {
              this.ioDayIndex = index;
              this.setDay(day, index);

              if (inTime[0]) {
                let tempIntime = inTime[0];
                if (inTime[0].length < 2) {
                  tempIntime = (inTime[0] > 9 ? "" : "0") + inTime[0];
                }
                let foundHr =
                  tempIntime +
                  ":00-" +
                  (parseInt(inTime[0]) + 1 > 9 ? "" : "0") +
                  (parseInt(inTime[0]) + 1) +
                  ":00";

                document.querySelectorAll(".io_hrs").forEach((element) => {
                  if (element.innerHTML.trim() == foundHr) {
                    element.classList.add("highlighted");
                  } else {
                    element.classList.remove("highlighted");
                  }
                });
              }
            }
          });
        }
      }
    });
  }

  // sets the selected day
  setDay(selDay, i) {
    // set the day
    this.ioDay = selDay;
    this.ioDayIndex = i;
    this.selectedDate = selDay.dayDate;

    // calculate the values
    let properties = calcTotalProperties(this.ioDay, true, {
      bolusProps: this.bolusProps,
      infusionProps: this.infusionProps,
      ioProps: this.ioProps,
    });
    this.inFeeds = properties.feedsTemp;
    this.inBlood = properties.bloodTemp;
    this.inInf = properties.infTemp;
    this.inBol = properties.bolTemp;
    this.inMeds = properties.medsTemp;
    this.otDrain = properties.drainsTemp;
    this.otProcedure = properties.procedureTemp;
    this.otDialysis = properties.dialysisTemp;

    this.inPerHr = properties.inTemp;
    this.outPerHr = properties.outTemp;

    this.intakeTotal = properties.totalIntake;
    this.outputTotal = properties.totalOutput;
  }

  /**
   * @description When Date Changes the selected day should change and update its contents
   * @param Date Date Variable
   * @date 17 Aug 2019
   */
  onClickOfDate(date: Date) {
    if (!date) return;
    this.selectedDate = this._tz.transformIntoTimezoneObj(date);
    this.ioDayIndex = this.datechange.findElementByDate(
      this.ioObj,
      date,
      "dayDate"
    );
    if (this.ioDayIndex > -1)
      this.setDay(this.ioObj[this.ioDayIndex], this.ioDayIndex);
  }

  /**
   * @description Filters only required date for vitals
   * @param Date Date Variable
   * @date 17 Aug 2019
   */
  dateFilter = (date: Date | null): boolean => {
    return this.datechange.filterDate(this.ioObj, date, "dayDate");
  };

  // prefill output
  fillInputScreen(date, hourName, minName, catProps, clickedObj) {
    if (
      clickedObj?.image &&
      !clickedObj.image.isVerified &&
      !clickedObj.amount
    ) {
      clickedObj.amount = "";
    }

    let data = {
      date: this._tz.transformIntoTimezoneObj(date),
      hourName: hourName,
      minName: minName,
      catInfo: catProps,
      clickedObj: clickedObj,
    };
    this.store.dispatch(ioActions.setClickedTime({ day: data }));
  }

  // get report
  public summaryHead = {};
  public reportVal;

  getReport() {
    let setSelectValues = (hrAmount, strDay) => {
      let strDateObj = this._tz.transformIntoTimezoneObj(strDay);

      if (hrAmount) {
        this.summaryHead["date"] = strDateObj;
      } else {
        this.summaryHead = {};
      }
    };

    let hrAmount = this.reportVal;

    let strDateObj, endDateObj;
    if (hrAmount == "date_range") {
      hrAmount = "";
      const fromTime = this.reportFromTime?.value,
        toTime = this.reportToTime?.value;
      const isDateTimeRange = fromTime && toTime;
      if (isDateTimeRange) {
        strDateObj = this._tz.transformIntoTimezoneObj(fromTime);
        endDateObj = this._tz.transformIntoTimezoneObj(toTime);
        hrAmount = (endDateObj.valueOf() - strDateObj.valueOf()) / 2.77778e-7;
      }
    } else if (hrAmount != "") {
      let nowDate = this._tz.getCurrentTimeObj();
      let strDay = nowDate.hour(nowDate.hour() - hrAmount);
      strDateObj = this._tz.transformIntoTimezoneObj(strDay);

      endDateObj = this._tz.getCurrentTimeObj();
    }

    let nowDate = this._tz.getCurrentTimeObj();
    let strDay = nowDate.hour(nowDate.hour() - hrAmount);

    if (hrAmount) {
      this.ioReport.showReport = true;

      // fill the props
      for (let index = 0; index < this.ioObj.length; index++) {
        let day = this.ioObj[index];

        if (!day.hours[0]["hourTotal"]) {
          let tempDay = calcTotalProperties(day, false, {
            bolusProps: this.bolusProps,
            infusionProps: this.infusionProps,
            ioProps: this.ioProps,
          });
          day = tempDay.ioDay;
        }

        let tempDay = this._tz.transformIntoTimezoneObj(day.dayDate);
        if (tempDay.valueOf() < strDateObj.valueOf()) {
          break;
        }
      }
    } else {
      setSelectValues(hrAmount, strDay);

      this.ioReport = {
        showReport: false,
        intakeReport: 0,
        outputReport: 0,
        netReport: 0,
        avgUrine: 0,
      };

      return false;
    }

    setSelectValues(hrAmount, strDateObj);

    // get report
    let netReportObj = getNetReport.call(
      this._tz,
      this.ioObj,
      strDateObj,
      endDateObj
    );

    this.ioReport.intakeReport = netReportObj.inReport;
    this.ioReport.outputReport = netReportObj.outReport;
    this.ioReport.netReport = netReportObj.netReport;
    this.ioReport.avgUrine = netReportObj.avgUrine;
    this.isReportFieldChange = false;
  }

  changeTrigger(value) {
    this.isReportFieldChange = true;
    this.ioReport.showReport = false;
    this.reportVal = value;

    if (value == "date_range") {
      this.rangedDate = true;
    } else {
      this.rangedDate = false;
      this.getReport();
    }
  }

  public rangedDate = false;

  // hide show accordian
  showAccordian(type: string) {
    switch (type) {
      case "feed":
        this.accordianProperty.showFeed = !this.accordianProperty.showFeed;
        break;
      case "blood":
        this.accordianProperty.showBlood = !this.accordianProperty.showBlood;
        break;
      case "med":
        this.accordianProperty.showMed = !this.accordianProperty.showMed;
        break;
      case "infusion":
        this.accordianProperty.showInfusion =
          !this.accordianProperty.showInfusion;
        break;
      case "bolus":
        this.accordianProperty.showBolus = !this.accordianProperty.showBolus;
        break;
      case "drain":
        this.accordianProperty.showDrain = !this.accordianProperty.showDrain;
        break;
      case "procedure":
        this.accordianProperty.showProcedure =
          !this.accordianProperty.showProcedure;
        break;
      case "dialysis":
        this.accordianProperty.showDialysis =
          !this.accordianProperty.showDialysis;
        break;
    }
  }

  notifyUpdate() {
    // listen to loading props
    this.ioForm$.subscribe((data) => {
      if (data.showSuccess) {
        this.showMessage("Success", "Data updated");
        setTimeout(() => {
          this.store.dispatch(ioActions.hideSuccess());
        }, 2000);
      } else if (data.error && !data.loading) {
        const status = "Error",
          message = data.error || "Server down! Please try after some time.";
        this.showMessage(status, message);
      }
    });
  }

  ngOnDestroy() {}

  public showMessage(status, message): void {
    const displayMessage: Alert = {
      type: status,
      message: message,
    };

    this._alertService.showNotification(
      displayMessage,
      "center",
      "bottom",
      3000
    );
  }

  public getContextMenuReport(data) {
    return {
      intakeReport: data.intakeReport,
      outputReport: data.outputReport,
      netReport: data.netReport,
      avgUrine: data.avgUrine,
    };
  }

  public getContextMenuHour(hour, date) {
    return {
      timestamp: {
        date,
        hour:
          (hour.hourName > 9 ? "" : "0") +
          hour.hourName +
          ":00 - " +
          (hour.hourName + 1 > 9 ? "" : "0") +
          (hour.hourName + 1 > 24 ? "00" : hour.hourName + 1) +
          ":00",
      },
      data: hour.hourTotal,
    };
  }

  public onContextMenu($event: MouseEvent, content: any, type: string): void {
    this.cpContextMenuService.onContextMenu($event, { content, type });
  }
  getTimeStamp(hour, minute) {
    const inputDate = this._tz.transformIntoTimezoneObj(this.selectedDate);

    // Use Date object methods to add hours and minutes
    inputDate.hour(hour);
    inputDate.minute(minute);

    // Format the resulting date as a string in ISO 8601 format
    const resultDateStr = inputDate.toISOString();

    return resultDateStr;
  }
  getHourRange(hour) {
    let hourValue = hour.hourName;
    let minuteValue = hour.minutes[hour.minutes.length - 1].minuteName;

    let transformedValue = this.getTimeStamp(hourValue, minuteValue);

    const timeFormate = "DD-MM-yyyy HH:mm";
    let fullDate: any = this.timeZone.transform(
      transformedValue.toString(),
      timeFormate
    );

    const fullDateParts = fullDate.split(" ");
    const timePart = fullDateParts[1]; // Get the time part
    const hourPart = timePart.split(":")[0]; // Extract the hour part
    const finalHour = parseInt(hourPart);

    return (
      (finalHour > 9 ? "" : "0") +
      finalHour +
      ":" +
      "00" +
      "-" +
      (finalHour + 1 > 9 ? "" : "0") +
      (finalHour + 1 > 24 ? "00" : finalHour + 1) +
      ":" +
      "00"
    );
  }
}
