import { Component, inject, Input, OnInit } from "@angular/core";
import { DatePipe, TitleCasePipe } from "@angular/common";

import { select, Store } from "@ngrx/store";
import * as fromOrder from "../../../store/reducers/order";

import { IcuDaysPipe } from "@shared-modules/pipes/icu-days-pipe/icu-days.pipe";
import { UtilService } from "src/app/services/util.service";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { DaysPassedPipe } from "src/app/shared/daysPassedPipe/daysPassed.pipe";

import { Patient, PatientType } from "src/app/models/patient";
import { Alert } from "src/app/iris-components/model/iris-component.model";

import { isEmpty } from "lodash-es";
import { CellInput, jsPDFDocument, RowInput } from "jspdf-autotable";
import { displayAge } from "src/app/support-functions/calculateAge";
import { TimezoneService } from "src/app/services/timezone.service";

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

@Component({
  selector: "app-print-orders",
  templateUrl: "./print-orders.component.html",
  styleUrls: ["./print-orders.component.scss"],
})
export class PrintOrdersComponent implements OnInit {
  imgData = null;
  @Input() type = "active";
  @Input() orderType: "drug" | "nonDrug" | null;

  titlePipe = new TitleCasePipe();
  daysPassed = new DaysPassedPipe();

  ordersFlag = [];
  orders$ = this.store.pipe(select(fromOrder.getOrders));
  drugOrderList = [];
  nonDrugOrderList = [];

  _currentPatient: Patient;
  @Input() set currentPatient(value: Patient) {
    this._currentPatient = value;
    if (value?.hospitalLogo) {
      this.utils.getDataUri(value["hospitalLogo"]).then((info) => {
        this.imgData = info;
      });
    }
  }
  get currentPatient(): Patient {
    return this._currentPatient;
  }

  private _tz = inject(TimezoneService);

  constructor(
    private store: Store<{}>,
    private utils: UtilService,
    private icuDaysPipe: IcuDaysPipe,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.orders$.subscribe((orders) => {
      this.ordersFlag = orders;
    });
  }

  info() {
    const displayMessage: Alert = {
      type: "Info",
      message: "No data to print",
    };

    this._alertService.showNotification(displayMessage, "center", "bottom");
  }

  setPdfFileNameAndHeader(doc: jsPDFDocument): jsPDFDocument {
    // PDF file name
    doc.setProperties({
      title: `${this.currentPatient.name} ${this.currentPatient.lastName}_${
        this.currentPatient.MRN
      }_${this._tz.getCurrentTimeObj().toDate()}`,
      author: "Cloudphysician",
      creator: "Cloudphysician",
    });

    // File header
    doc.autoTable({
      body: [
        [
          {
            content: "Orders Report",
            styles: {
              halign: "center",
              fontSize: 14,
              fontStyle: "bold",
              textColor: "#2B2A3A",
              fillColor: [255, 255, 255],
            },
          },
        ],
      ],
    });

    return doc;
  }

  setPatientInfo(doc: jsPDFDocument, topY: number): jsPDFDocument {
    // Patient Info Table
    function getRoundedValue(value) {
      return Math.round(value * 100) / 100;
    }

    const {
      ICUAdmitDate,
      isCurrentlyAdmitted,
      ICUDischargeDate,
      name,
      lastName,
      MRN,
      sex,
      unitName,
      bedNo,
      height,
      weight,
      bloodGroup,
      allergies,
      BMI,
      patientType,
    } = this.currentPatient;

    doc.autoTable({
      startY: topY,
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
      },
      head: [["Patient information"]],
      styles: { fontSize: 10 },
    });

    const noOfIcuDays = ICUAdmitDate
      ? this.icuDaysPipe.transform(
          ICUAdmitDate,
          !isCurrentlyAdmitted && ICUDischargeDate
        )
      : null;
    const admitDate = ICUAdmitDate
      ? this._tz.getPatDatetimeStrTz(ICUAdmitDate)
      : "-";
    const tableBody: RowInput[] = [
      [
        { content: `Name: ${this.utils.getName(name, lastName)}` },
        { content: `MRN: ${MRN || "-"}` },
      ],
      [
        { content: `Age / Gender: ${this.getPatientAge()} / ${sex || "-"}` },
        { content: `Unit / Bed no.: ${unitName || "-"} / ${bedNo || "-"}` },
      ],
      [
        {
          content: `Height / Weight: ${
            height ? getRoundedValue(height) + "cm" : "-"
          } / ${weight ? getRoundedValue(weight) + "kg" : "-"}`,
        },
        {
          content: `Admit date: ${admitDate}`,
        },
      ],
    ];

    const bloodGroupCell: CellInput = {
      content: `Blood Group: ${bloodGroup || "-"}`,
    };
    const allergiesCell: CellInput = {
      content: `Allergies: ${allergies?.length ? allergies.join(", ") : "-"}`,
    };
    const ICUDaysCount: CellInput = {
      content: `No of days in ICU: ${noOfIcuDays || "-"}`,
    };
    const BMICell: CellInput = {
      content: `BMI: ${BMI > 0 ? BMI?.toFixed(2) : "-"}`,
    };

    if (patientType === PatientType.Neonatal) {
      tableBody.push([bloodGroupCell, allergiesCell]);
      tableBody.push([ICUDaysCount]);
    } else {
      tableBody.push([bloodGroupCell, BMICell]);
      tableBody.push([allergiesCell, ICUDaysCount]);
    }

    doc.autoTable({
      styles: { fontSize: 10 },
      startY: topY + 8,
      columnStyles: {
        0: { cellWidth: 91 },
        1: { cellWidth: 91 },
      },
      body: tableBody,
    });

    // Setting image header
    this.utils.setImageHeader({ doc, imgData: this.imgData, valign: "middle" });

    return doc;
  }

  setPatientSubsequentInfo(doc: jsPDFDocument): jsPDFDocument {
    // Patient subsequent info table
    const pageCount = doc.internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      if (i !== 1) {
        doc.setPage(i);

        doc.autoTable({
          startY: 14,
          body: [
            [
              {
                content: "Orders Report",
                styles: {
                  halign: "center",
                  fontSize: 14,
                  fontStyle: "bold",
                  textColor: "#2B2A3A",
                  fillColor: [255, 255, 255],
                },
              },
            ],
          ],
        });

        doc.autoTable({
          startY: 30,
          headStyles: {
            fillColor: "#E2E8F4",
            textColor: [0, 0, 0],
          },
          head: [["Patient information"]],
          styles: { fontSize: 10 },
        });

        doc.autoTable({
          styles: { fontSize: 10 },
          startY: 38,
          columnStyles: {
            0: { cellWidth: 33 },
            1: { cellWidth: 58 },
            2: { cellWidth: 33 },
            3: { cellWidth: 58 },
          },
          body: [
            [
              "Name: ",
              `${
                this.currentPatient["name"] +
                  " " +
                  this.currentPatient["lastName"] || "-"
              } `,
              "MRN: ",
              `${this.currentPatient["MRN"] || "-"}`,
            ],
            [
              "Age / Gender: ",
              `${
                displayAge(
                  this.currentPatient.age,
                  false,
                  this.currentPatient.patientType,
                  this.currentPatient.dob
                ) || "-"
              }`,
              "Bed no: ",
              `${this.currentPatient["bedNo"] || "-"}`,
            ],
          ],
        });

        // Setting image header
        this.utils.setImageHeader({
          doc,
          imgData: this.imgData,
          valign: "middle",
        });
      }
    }

    return doc;
  }

  setPdfFileFooter(doc: jsPDFDocument): jsPDFDocument {
    // set footer
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.line(8, 323 - 34, 202, 323 - 34);

      doc.setFontSize(6);
      doc.text(
        `Page ${String(i)} of ${String(pageCount)}, Report created on RADAR`,
        10,
        323 - 31
      );
      doc.text(
        `Printed on: ${this._tz.getPatDatetimeStrTz(
          undefined,
          "DD/MM/yy, H:mm"
        )}`,
        210 - 45,
        323 - 31
      );
    }

    return doc;
  }

  convertToPdfFormat(orders: any, type: "drug" | "nonDrug" = "drug") {
    const result = [];
    orders.forEach((order: any) => {
      const row = [];
      const fillColor = [255, 255, 255];

      row.push({
        content: this._getName(order),
        styles: {
          fillColor,
        },
      });
      if (type == "drug") {
        row.push({
          content: order.brandName || "-",
          styles: {
            fillColor,
          },
        });
      }
      this._appendDosage({ row, order, fillColor });
      this._appendFrequency({ row, order, fillColor });
      row.push({
        content: order.route || "-",
        styles: {
          fillColor,
        },
      });
      this._appendCombMeds({ row, order, fillColor, result });
      this._appendOrderInfo({ order, fillColor, result });
      result.push(this._emptyRow());
    });

    return result;
  }

  private _appendOrderInfo({ order, fillColor, result }): void {
    if (order?.instructions) {
      result.push([
        {
          content: `Instructions: ${order.instructions}`,
          colSpan: 5,
          styles: {
            fillColor,
          },
        },
      ]);
    }

    if (order?.additionalInformation) {
      result.push([
        {
          content: `Additional Instructions: ${order.additionalInformation}`,
          colSpan: 5,
          styles: {
            fillColor,
          },
        },
      ]);
    }

    result.push([
      {
        content: `Start: ${this._tz.getPatDatetimeStrTz(
          order?.startTime,
          "DD/MM/yy, H:mm"
        )}, ${this._getMedDay(order)}`,
        colSpan: 5,
        styles: {
          fillColor,
        },
      },
    ]);
  }

  private _appendCombMeds({ row, order, fillColor, result }): void {
    if (order?.combination?.length) {
      row[3]["rowSpan"] = order?.combination?.length + 1;
      row[4]["rowSpan"] = order?.combination?.length + 1;
      row[3]["styles"]["valign"] = "middle";
      row[4]["styles"]["valign"] = "middle";

      result.push(row);

      order.combination.forEach((combo) => {
        const subRow = [];

        subRow.push({
          content: combo.name || "-",
          styles: {
            fillColor,
          },
        });

        subRow.push({
          content: "-",
          styles: {
            fillColor,
          },
        });

        subRow.push({
          content: combo.quantity + " " + combo.unit,
          styles: {
            fillColor,
          },
        });

        result.push(subRow);
      });
    } else {
      result.push(row);
    }
  }
  private _appendFrequency({ row, order, fillColor }): void {
    let content = "-";
    if (order?.frequency?.fType) {
      if (order?.frequency?.fType == "every") {
        content = "every ";

        if (order?.frequency?.days) {
          content += `${order?.frequency?.days} days `;
        }

        if (order?.frequency?.hours) {
          content += `${order?.frequency?.hours} hours `;
        }

        if (order?.frequency?.mins) {
          content += `${order?.frequency?.mins} minutes `;
        }
      } else if (order?.frequency?.fType == "once") {
        content = "once";
      } else {
        content = "continuous";
      }
    }
    row.push({
      content,
      styles: {
        fillColor,
      },
    });
  }
  private _appendDosage({ row, order, fillColor }): void {
    if (order.quantity || order.doseRange) {
      const dosage = order.quantity || order.doseRange;
      const dosageUnit =
        order.type == "bloods" ? order.quantityUnit : order.unit;

      row.push({
        content: order.maxDose
          ? dosage + " - " + order.maxDose + " " + dosageUnit
          : dosage + " " + dosageUnit,
        styles: {
          fillColor,
        },
      });
    } else {
      row.push({
        content: "-",
        styles: {
          fillColor,
        },
      });
    }
  }

  private _getName(order): string {
    if (order.type == "procedures") return order.pType;
    if (order.type == "vents") return order.airway;
    if (order.type === "bloods" || order.type === "communications")
      return order.title;
    if (order.type === "labs") return order.investigation;
    return order.name;
  }

  private _emptyRow() {
    return [
      {
        content: "",
        colSpan: 5,
        styles: {
          minCellHeight: 1,
          fillColor: [255, 255, 255],
          cellPadding: {
            horizontal: 0,
            vertical: 0,
          },
          lineWidth: {
            top: 0.1,
          },
        },
      },
    ];
  }

  private _getMedDay(order): string {
    const daysPassed: number =
      this.daysPassed.transform(
        order,
        this.type,
        true,
        this.currentPatient.ICUDischargeDate
      )?.day || 0;
    return `${daysPassed <= 0 ? "Scheduled" : "Day: " + daysPassed}`;
  }

  setMedHeader(
    doc: jsPDFDocument,
    topY: number,
    sectionName: string
  ): jsPDFDocument {
    doc.autoTable({
      startY: topY,
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
      },
      pageBreak: "avoid",
      rowPageBreak: "auto",
      margin: {
        top: 60,
        fillColor: [213],
      },
      head: [[`${sectionName}`]],
      styles: { fontSize: 10 },
    });
    return doc;
  }

  generateDrugSection(
    section: string,
    doc: jsPDFDocument,
    topY: number,
    pdfFormatedMed
  ): { doc: jsPDFDocument; topY: number } {
    const head = [
      {
        content: "Name",
        styles: {
          cellWidth: 55,
        },
      },
      {
        content: "Brand",
        styles: {
          cellWidth: 40,
        },
      },
      {
        content: "Dose",
        styles: {
          cellWidth: 29,
        },
      },
      {
        content: "Frequency",
        styles: {
          cellWidth: 29,
        },
      },
      {
        content: "Route",
        styles: {
          cellWidth: 29,
        },
      },
    ];

    return this.generateSection({ section, doc, topY, pdfFormatedMed, head });
  }

  generateNonDrugSection(
    section: string,
    doc: jsPDFDocument,
    topY: number,
    pdfFormatedMed
  ): { doc: jsPDFDocument; topY: number } {
    const head = [
      {
        content: "Name",
        styles: {
          cellWidth: 79,
        },
      },
      {
        content: "Dose",
        styles: {
          cellWidth: 45,
        },
      },
      {
        content: "Frequency",
        styles: {
          cellWidth: 29,
        },
      },
      {
        content: "Route",
        styles: {
          cellWidth: 29,
        },
      },
    ];
    return this.generateSection({ section, doc, topY, pdfFormatedMed, head });
  }

  generateSection(params: {
    section: string;
    doc: jsPDFDocument;
    topY: number;
    pdfFormatedMed;
    head;
  }) {
    let { section, doc, topY, pdfFormatedMed, head } = params;
    if (topY >= 200) {
      doc.addPage();
      topY = 60;
    }

    doc = this.setMedHeader(doc, topY, section);
    topY = doc.lastAutoTable.finalY + 1;

    if (topY >= 200) {
      doc.addPage();
      topY = 60;
    }

    doc.autoTable({
      startY: topY + 2,
      theme: null,
      rowPageBreak: "auto",
      showHead: "everyPage",
      margin: {
        top: 60,
        fillColor: [213],
      },
      styles: {
        minCellHeight: 1,
        fontSize: 10,
        lineWidth: 0.1,
      },
      headStyles: {
        lineColor: false,
        fillColor: [242, 245, 241],
        textColor: [0, 0, 0],
      },
      head: [head],
      body: pdfFormatedMed,
    });

    return {
      doc,
      topY: doc.autoTable.previous.finalY,
    };
  }

  print() {
    if (
      this.ordersFlag &&
      this.type &&
      this.ordersFlag[this.type] &&
      this.ordersFlag[this.type]["medications"]
    ) {
      const meds = this.ordersFlag[this.type]["medications"];
      this.drugOrderList = meds.sort((a, b) =>
        this._getName(a).localeCompare(this._getName(b))
      );
    }
    this.nonDrugOrderList = this.getNonDrugOrder(this.ordersFlag[this.type]);
    this._createPDF();
  }

  private _createPDF(): void {
    let doc: jsPDFDocument = new jsPDF();
    doc = this.setPdfFileNameAndHeader(doc);

    let topY = doc.lastAutoTable.finalY + 5;
    doc = this.setPatientInfo(doc, topY);
    topY = doc.lastAutoTable.finalY + 6;

    const drugBodyData = this.convertToPdfFormat(this.drugOrderList);
    const nonDrugOBodyData = this.convertToPdfFormat(
      this.nonDrugOrderList,
      "nonDrug"
    );
    const isDrug = !isEmpty(drugBodyData) && this.orderType != "nonDrug";
    const isNonDrug = !isEmpty(nonDrugOBodyData) && this.orderType != "drug";
    if (isDrug) {
      const drugResult = this.generateDrugSection(
        "Drug",
        doc,
        topY,
        drugBodyData
      );
      doc = drugResult.doc;
      topY = drugResult.topY;
    }
    if (isNonDrug) {
      this.generateNonDrugSection("Non Drug", doc, topY, nonDrugOBodyData);
    }
    if (!isDrug && !isNonDrug) {
      this.info();
      return;
    }
    doc = this.setPdfFileFooter(doc);
    doc = this.setPatientSubsequentInfo(doc);
    this.launchPdfViewer(doc);
  }

  getNonDrugOrder(orderList: any): any[] {
    let totalNonDrug = [];
    const otherMeds = [
      "bloods",
      "labs",
      "diets",
      "procedures",
      "vents",
      "communications",
    ];
    for (const index in otherMeds) {
      const order = otherMeds[index];
      if (!Array.isArray(orderList[order])) continue;
      const sortedOrder = orderList[order].sort((a, b) =>
        this._getName(a).localeCompare(this._getName(b))
      );
      totalNonDrug = [...totalNonDrug, ...sortedOrder];
    }
    return totalNonDrug;
  }

  launchPdfViewer(doc: jsPDFDocument): void {
    //// this.store.dispatch(MarStatusActions.setLoader({ loading: false }));

    const pdfWindow = window.open("", "", "width=700,height=900");
    pdfWindow.document.write(
      "<iframe style='width: 100%; height: 70rem' src='" +
        doc.output("datauristring") +
        "' frameborder='0'></iframe>"
    );
    pdfWindow.document.close();
  }

  getPatientAge() {
    const ageObj = this._currentPatient?.age;

    return displayAge(
      ageObj,
      false,
      this._currentPatient.patientType,
      this._currentPatient.dob
    );
  }
}
