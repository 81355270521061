import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromRoot from "../index";
import * as fromAuthOtp from "./auth-otp.reducer";
import * as fromAuthForm from "./auth-form.reducer";
import * as fromAuthMisc from "./auth-misc.reducer";
import * as fromAuthError from "./auth-error.reducer";
import * as fromAuthFamilyOtp from "./auth-family-otp.reducer";

export interface AuthState {
  form: fromAuthForm.State;
  error: fromAuthError.State;
  otp: fromAuthOtp.State;
  misc: fromAuthMisc.State;
  familyOtp: fromAuthFamilyOtp.State;
}

export interface State extends fromRoot.AppState {
  auth: AuthState;
}

/** REDUCER setup */
export const reducer: ActionReducerMap<any, any> = {
  form: fromAuthForm.reducer,
  error: fromAuthError.reducer,
  otp: fromAuthOtp.reducer,
  misc: fromAuthMisc.reducer,
  familyOtp: fromAuthFamilyOtp.reducer,
};

/** SELECTOR setup */
export const getAuthState = createFeatureSelector<AuthState>("auth");

export const getAuthFormState = createSelector(
  getAuthState,
  (state: AuthState) => {
    return state.form;
  }
);

// All error state

export const getOtpAuthOtpFormErrorState = createSelector(
  getAuthFormState,
  (state: fromAuthForm.State) => state.otpLoginError
);

export const getEmailAuthFormErrorState = createSelector(
  getAuthFormState,
  (state: fromAuthForm.State) => state.emailLoginError
);

/*
 * Auth Phone number Error State
 * */

export const getMobileAuthFormErrorState = createSelector(
  getAuthFormState,
  (state: fromAuthForm.State) => state.mobileLoginError
);

/*
 * Auth Family Error State
 * */

export const getFamilOtpFormErrorState = createSelector(
  getAuthFormState,
  (state: fromAuthForm.State) => state.familyLoginError
);

// All error state

export const getAuthFormLoadingState = createSelector(
  getAuthFormState,
  (state) => state.loading
);

export const getAuthFormSuccessMsg = createSelector(
  getAuthFormState,
  fromAuthForm.getAuthFormSuccessMsg
);

/*
 * Auth Error State
 * */

export const getAuthErrorState = createSelector(
  getAuthState,
  (state: AuthState) => state.error
);

export const getAuthErrorStatus = createSelector(
  getAuthErrorState,
  fromAuthError.getAuthErrorStatus
);

export const getAuthErrorMessage = createSelector(
  getAuthErrorState,
  fromAuthError.getAuthErrorMessage
);

/*
 * Auth Otp State
 * */
export const getAuthOtpState = createSelector(
  getAuthState,
  (state: AuthState) => state.otp
);

export const getAuthOtpUuid = createSelector(
  getAuthOtpState,
  fromAuthOtp.getAuthOtpUuid
);

export const getAuthOtpPhone = createSelector(
  getAuthOtpState,
  fromAuthOtp.getAuthOtpPhone
);

export const getAuthCountryCode = createSelector(
  getAuthOtpState,
  fromAuthOtp.getAuthCountryCode
);

/*
 * Auth Misc State
 * */
export const getAuthMiscState = createSelector(
  getAuthState,
  (state: AuthState) => state.misc
);

export const getAuthMiscScreen = createSelector(
  getAuthMiscState,
  fromAuthMisc.getAuthMiscScreen
);

export const getAuthMiscLoading = createSelector(
  getAuthMiscState,
  fromAuthMisc.getAuthMiscLoading
);

export const getAuthMiscSuccessMsg = createSelector(
  getAuthMiscState,
  fromAuthMisc.getAuthMiscSuccessMsg
);

export const getAuthMiscError = createSelector(
  getAuthMiscState,
  fromAuthMisc.getAuthMiscError
);

export const getResetPasswordState = createSelector(
  getAuthMiscState,
  fromAuthMisc.getResetPasswordState
);

// Family login
export const getAuthFamilyOtpState = createSelector(
  getAuthState,
  (state: AuthState) => state.familyOtp
);

export const getAuthFamilyOtpLoadingState = createSelector(
  getAuthFamilyOtpState,
  (state: fromAuthFamilyOtp.State) => state.loading
);

export const getAuthFamilyOtpErrorState = createSelector(
  getAuthFamilyOtpState,
  (state: fromAuthFamilyOtp.State) => state.error
);

export const getAuthFamilyOtpDataState = createSelector(
  getAuthFamilyOtpState,
  (state: fromAuthFamilyOtp.State) => state.data
);

export const getAuthFamilyOtpSuccessState = createSelector(
  getAuthFamilyOtpState,
  (state: fromAuthFamilyOtp.State) => state.success
);
